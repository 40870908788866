const QuizData = [
    {
        "question": "Where did Jake and Fiona get engaged?",
        "answers": ['Galway, Ireland', 'Westport, Ireland', 'Edinburgh, Scotland', 'Dublin, Ireland'],
        "correctAnswer": 1
    },
    {
        "question": "Which celebrity does Jake think Fiona looks like?",
        "answers": ['Léa Seydoux', 'Emma Watson', 'Lorde', 'Charlie XCX'],
        "correctAnswer": 0
    },
    {
        "question": "What was the name of Jake's Miata?",
        "answers": ['Pearl, The Snowqueen of Texas', 'Megatron', 'Lil Miyachty', 'Casey Jones'],
        "correctAnswer": 0
    },
    {
        "question": "Which celebrity does Fiona think Jake looks like?",
        "answers": ['Alex Pearlman','Jason Momoa', 'Hozier', 'Nandor the Relentless'],
        "correctAnswer": 2
    },
    {
        "question": "What is the name of Jake and Fiona's dog?",
        "answers": ['Possum', 'Duck', 'Maverick', 'Goose'],
        "correctAnswer": 3
    },
    {
        "question": "Where did Jake and Fiona meet?",
        "answers": ['Miami university', 'Pittsburgh', 'Cuyahoga Valley National Park', 'John Carroll'],
        "correctAnswer": 3
    },
    {
        "question": "Where was Jake and Fiona's first date?",
        "answers": ['Cuyahoga Valley National Park', "Swenson's Drive-in", "Dewey's Pizza", "Cleveland Museum of Art" ],
        "correctAnswer": 0
    },
    {
        "question": "What is Jake's favorite National Park?",
        "answers": ['Grand Canyon', 'Great Sand Dune', 'St Louis Arch', 'Hot Springs'],
        "correctAnswer": 1
    },
    {
        "question": "What does Fiona's acrostic engagement ring spell?",
        "answers": ["Adore", 'Amore', 'Aisuru', 'Searc' ],
        "correctAnswer": 3
    },
    {
        "question": "What did Jake study in college?",
        "answers": ["Computer Science", "Statistics", "Electrical Engineering", "Applied Maths"],
        "correctAnswer": 0
    },
    {
        "question": "What did Fiona study in college?",
        "answers": [ 'English', 'Studio Art', 'Psychology', 'Social Studies Education'],
        "correctAnswer": 2
    },
    {
        "question": "Where was Fiona born?",
        "answers": ['Cleveland', 'Chicago', 'Pittsburgh', 'Philadelphia'],
        "correctAnswer": 3
    },
    {
        "question": "What is Fiona's natural hair color?",
        "answers": ['Red', 'Black', 'Blonde', 'Brown'],
        "correctAnswer": 2
    },
    {
        "question": "Who has more Siblings?",
        "answers": ["Fiona", "Jake"],
        "correctAnswer": 1
    },



];
export default QuizData